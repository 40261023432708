@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

#react-doc-viewer #header-bar {
    display: none;
}

#react-doc-viewer #proxy-renderer, #react-doc-viewer #proxy-renderer #msdoc-renderer {
    height: 100%;
}

.ql-toolbar.ql-snow {
    border-radius: 5px 5px 0 0;
}

.ql-container.ql-snow {
    border-radius: 0 0 5px 5px;
}

.event-text a {
    text-decoration: underline;
}

.chat-detail .ScrollbarsCustom-Content {
    display: flex;
}

.chat-detail .ScrollbarsCustom-Wrapper {
    inset: 0px 5px 0px 0px !important;
}

.ql-editor {
    max-height: 100px;
}

.scrollbar > div:first-child {
    overflow-x: hidden !important;
}

.message a {
    text-decoration: underline;
}

.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background: white;
    padding: 20px;
}